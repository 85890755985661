export const environment = {
  production: true,
  defaultauth: 'fakebackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  baseUrl: 'https://apis.ptjis.id',
  googleSiteKey: '6LcnBukpAAAAAOjejhF-dF_rsTHGXKXY1IMee5ZV',
};
