import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'app-page-loader',
  standalone: true,
  imports: [],
  templateUrl: './page-loader.component.html',
  styleUrl: './page-loader.component.scss'
})
export class PageLoaderComponent implements OnInit, AfterViewInit, AfterContentChecked {

    isLoading!: boolean;

	constructor(
		private loaderService: LoaderService,
		private cd: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.loaderService.isLoading.subscribe(resp => {
			this.isLoading = resp;
		});
	}

	ngAfterViewInit() { this.cd.detectChanges(); }

	ngAfterContentChecked() {
		this.cd.detectChanges();
	}
}
