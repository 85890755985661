import { Routes } from '@angular/router';
import { LayoutComponent } from './modules/layouts/layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginPageGuard } from './core/guards/login-page.guard';
import { RoleGuard } from './core/guards/role-guard';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: '', component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import('./modules/pages/pages.route').then(mod => mod.PAGE_ROUTES)
    },
    {
        path: 'auth',
        canActivate: [LoginPageGuard],
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    }
];


