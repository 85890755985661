import {Injectable} from '@angular/core';
import {CanActivate, NavigationEnd, Router} from '@angular/router';
import {SessionService} from '../services/session.service';
import { filter } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(
        private sessionService: SessionService,
        private router: Router,
        private toastrService: ToastrService
        ) {
    }

    public canActivate(): boolean {
        const roleMatrix = this.sessionService.getAccountSession().roleMatrix;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                const url = window.location.href;
                let path = new URL(url).pathname;
                if (path.startsWith("/")) {
                    path = path.substring(1);
                }

                const [module, feature] = path.split('/');
                const role: any = roleMatrix.find(item => item.module === module);

                if (role) {
                    const action = role.action[feature];
                    if (action !== undefined && action) {
                        // User has access
                        return true
                    } else if (action !== undefined && !action) {
                        // User does not have access
                        this.toastrService.warning("Anda tidak memiliki akses untuk fitur ini"),
                        setTimeout(() => {
                            this.router.navigate([module]);
                        }, 1000);
                        return false;
                    } else {
                        //module include in matrix but not the feature
                        return false;
                    }
                } else {
                    // Module not found in role matrix, Can access freely
                    return true
                }
            })
        return true;

    }

}
