import { AccountRole } from "./account-role.model";

export class Account {
    name!: string;
    userId!: string;
    roleName!: string;
    roleId!: number;
    roleMatrix!: AccountRole[];
    email!: string;
    token!: string;
}
