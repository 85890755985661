export const urlSegments  = ['detail', 'update'];

export const configUrlSegment = (url: string): string => {

    const cleanUrl = url.split('?')[0];

	const regex = /^\/([^\/]+)\/(detail)\/([^\/]+)(?:\/[^\/]+)?(?:\/[^\/]+)?$/i;
    const match = cleanUrl.match(regex);
	const segments = cleanUrl.split('/').filter(segment => segment);

	if(match && segments[0].includes('financial-statement')) {
		return `/${match[1]}/list/${match[3]}`;
	}

    // Handle URLs containing 'detail', 'update', or similar keywords and replace them with '/list'
    const detailOrUpdateRegex = /^\/([^\/]+(?:\/[^\/]+)*)\/(?:detail|update|revision)\/[^\/]+.*$/i;
    const detailOrUpdateMatch = cleanUrl.match(detailOrUpdateRegex);

    if (detailOrUpdateMatch) {
        return `/${detailOrUpdateMatch[1]}/list`;
    }

    // Handle URLs containing '/list'
    const listRegex = /^\/([^\/]+(?:\/[^\/]+)?\/list(?:\/[^\/]*)?)$/i;
    const listMatch = cleanUrl.match(listRegex);
    if (listMatch) {
        return `/${listMatch[1]}`;
    }

    // Handle URLs with the first two segments if no other pattern matches
    const multiSegmentRegex = /^\/([^\/]+\/[^\/]+).*$/i;
    const multiSegmentMatch = cleanUrl.match(multiSegmentRegex);
    if (multiSegmentMatch) {
        return `/${multiSegmentMatch[1]}`;
    }

	return url;
}



export const setAsActiveUrl = (url: string): string => {
	const regex = /^(\/[^\/]+\/list)/i;
	const match = url.match(regex);
	return match ? match[1] : url;
}
