import { ROUTER_PATH } from "../../../shared/constant/route-path.const";
import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] =[
  {
    id: 0,
    label: '',
    isTitle: true,
  },
  {
    id: 1,
    label: 'Dashboard',
    icon: 'layout-dashboard',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'Certificate',
    icon: 'scroll-text',
    subItems: [
      {
          id: 2.1,
          label: 'Add New',
          link: ROUTER_PATH.addCertificate,
          parentId: 2
      },
      {
          id: 2.2,
          label: 'List of Certificate',
          link: ROUTER_PATH.listCertificate,
          parentId: 2
      },
    ]
  },
  {
    id: 3,
    label: 'Principal',
    icon: 'briefcase',
    subItems: [
      {
          id: 3.1,
          label: 'Add New',
          link: ROUTER_PATH.addPrincipal,
          parentId: 3
      },
      {
          id: 3.2,
          label: 'List of Principal',
          link: ROUTER_PATH.listPrincipal,
          parentId: 3
      },
    ]
  },
  {
    id: 4,
    label: 'Insurance',
    icon: 'shield-check',
    link: '/insurance',
    subItems: [
      {
          id: 4.1,
          label: 'Add New',
          link: ROUTER_PATH.addInsurance,
          parentId: 4
      },
      {
          id: 4.2,
          label: 'List of Insurance',
          link: ROUTER_PATH.listInsurance,
          parentId: 4
      },
    ]
  },
  {
    id: 5,
    label: 'Sub Agent',
    icon: 'users',
    subItems: [
      {
          id: 5.1,
          label: 'Add New',
          link: ROUTER_PATH.addSubagent,
          parentId: 5
      },
      {
          id: 5.2,
          label: 'List of Sub Agent',
          link: ROUTER_PATH.listSubagent,
          parentId: 5
      },
    ]
  },
  {
    id: 6,
    label: 'Blanko',
    icon: 'book-check',
    subItems:[
        {
            id: 6.1,
            label: 'Tambah Blanko',
            link: ROUTER_PATH.addBlanko,
            parentId: 6
        },
        {
            id: 6.2,
            label: 'Daftar Blanko',
            link: ROUTER_PATH.listBlanko,
            parentId: 6
        },
        {
            id: 6.3,
            label: 'Rekap Blanko',
            link: ROUTER_PATH.recapBlanko,
            parentId: 6
        },
        {
            id: 6.4,
            label: 'Distribusi Blanko',
            link: ROUTER_PATH.distributeBlanko,
            parentId: 6
        },
    ]
  },
  {
    id: 7,
    label: 'Manajemen Pengguna',
    icon: 'user',
    link: 'user-management/list',
  },
  {
    id: 8,
    label: 'Laporan',
    icon: 'bar-chart-big',
    link: '/',
    subItems: [
        {
            id: 8.1,
            label: 'Laporan Produksi Asuransi',
            link: ROUTER_PATH.financialStatementInsuranceList,
            parentId: 8
        },
        {
            id: 8.2,
            label: 'Laporan Produksi',
            link: ROUTER_PATH.financialStatementProductionReport,
            parentId: 8
        },
    ]
  },
]
