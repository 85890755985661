import { Injectable, signal } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SidebarStore {
	parentActive = signal<string>('');
	menuActive = signal<string>('');
	isActive = signal<boolean>(false)
}
