import { CUSTOM_ELEMENTS_SCHEMA, Component, HostListener, Inject, Renderer2, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DrawerModule } from '../../../shared/components/drawer';
import { LUCIDE_ICONS, LucideAngularModule, LucideIconProvider, Route, icons } from 'lucide-angular';
import { MnDropdownComponent } from '../../../shared/components/dropdown';
import { LanguageService } from '../../../core/services/language.service';
import { Store } from '@ngrx/store';
import { getLayout, getSidebarsize } from '../../../store/layout/layout-selector';
import { changesidebarsize } from '../../../store/layout/layout-action';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SessionService } from '../../../core/services/session.service';
import { Account } from '../../../shared/model/account.model';
import { ROUTER_PATH } from '../../../shared/constant/route-path.const';


@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [DrawerModule, MnDropdownComponent, LucideAngularModule, SimplebarAngularModule, RouterModule, CommonModule],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA,],
  providers: [{ provide: LUCIDE_ICONS, multi: true, useValue: new LucideIconProvider(icons) }, LanguageService],
})
export class TopbarComponent {

  size: any;
  layout: any;

  public account: Account = new Account();
  private store = inject(Store);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sessionService: SessionService,
    private router: Router
    ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    var windowSize = document.documentElement.clientWidth;

    // Get Layout
    this.store.select(getLayout).subscribe((data) => {
      this.layout = data
    })

    if (this.layout == 'vertical') {
      if (windowSize > 768 && windowSize < 1024) {
        this.store.dispatch(changesidebarsize({ size: 'sm' }));
      } else {
        this.store.dispatch(changesidebarsize({ size: 'lg' }));
      }
    } else {
      this.store.dispatch(changesidebarsize({ size: 'lg' }));
    }
  }


  ngOnInit(): void {
    this.account = this.sessionService.getAccountSession();
  }

  // scroll
  windowScroll() {
    var scrollUp = document.documentElement.scrollTop;
    if (scrollUp >= 50) {
      document.getElementById("page-topbar")?.classList.add('is-sticky');
    } else {
      document.getElementById("page-topbar")?.classList.remove('is-sticky');
    }
  }

  changeSidebar() {
    var windowSize = document.documentElement.clientWidth;
    let sidebarOverlay = document.getElementById("sidebar-overlay") as any;

    if (windowSize < 768) {
      this.document.body.classList.add("overflow-hidden");
      // Check if the sidebar overlay is hidden
      if (sidebarOverlay.classList.contains("hidden")) {
        sidebarOverlay.classList.remove("hidden");
        this.document.documentElement.querySelector('.app-menu')?.classList.remove("hidden");
      } else {
        sidebarOverlay.classList.add("hidden");
        this.document.documentElement.querySelector('.app-menu')?.classList.add("hidden");
      }
      this.store.dispatch(changesidebarsize({ size: 'lg' }));
    } else {
      this.store.select(getSidebarsize).subscribe((size) => {
        this.size = size
      })
      this.store.dispatch(changesidebarsize({ size: this.size === "sm" ? "lg" : "sm" }));
    }
  }

  logOut(): void {
    this.sessionService.clearLocalStorage();
    window.location.reload();
  }
}
