import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";
import { NgxToastrService } from "../../shared/services/ngx-toastr.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  toastrService = inject(NgxToastrService);
  loaderService = inject(LoaderService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    if (!window.navigator.onLine) {
      this.loaderService.hide();
      this.toastrService.error(
        "Tidak ada koneksi internet, harap periksa jaringan Anda dan coba lagi."
      );
      return throwError(() => new Error("No internet connection"));
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loaderService.hide();
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.loaderService.hide();
            let msg: string;
            switch (err.status) {
              case 400:
                break;
              case 401:
                this.toastrService.warning(
                  "Sesi anda telah berakhir. Silakan login kembali untuk akses dashboard"
                );
                setTimeout(() => {
                  localStorage.clear();
                  window.location.reload();
                }, 3000);
                break;
              case 500:
                this.toastrService.error("Server sedang sibuk");
            }
          }
        }
      )
    );
  }
}
