import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {SessionService} from '../services/session.service';
import { ROUTER_PATH } from '../../shared/constant/route-path.const';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private sessionService: SessionService, private router: Router) {
    }

    public canActivate(): boolean {
        const token = this.sessionService.getTokenSession()
        if (token === "") {
            this.router.navigate([ROUTER_PATH.login]).then(r => {
            });
            return false
        } else {
            return true
        }
    }

}
